import { createAction } from 'redux-actions'

const Actions = {
  START_INIT: 'START_INIT',
  FINISH_INIT: 'FINISH_INIT',
  SET_TOKEN: 'SET_TOKEN',
  RECEIVE_REPORTS: 'RECEIVE_REPORTS'
}

export default Actions

export const startInit = createAction(Actions.START_INIT)
export const finishInit = createAction(Actions.FINISH_INIT)
export const setToken = createAction(Actions.SET_TOKEN)
export const receiveReports = createAction(Actions.RECEIVE_REPORTS)
