import React from 'react'
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
  ReferenceLine,
  LabelList,
  Tooltip
} from 'recharts'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectReports } from 'reports/scrapTrend/selectors'
import { FormattedMessage } from 'react-intl'
import { selectValueDomain } from './selectors'

const percentageFormatter = val => `${(val * 100).toFixed(2)} %`
const valueFormatter = value => `${(value / 1000.0).toFixed(2)}k`
const monthFormatter = month => moment(month).format('MMM YYYY')

const ScrapTrendTooltip = ({ payload }) => {
  if (!payload || !payload[0]) {
    return null
  }

  const { month, value, percentage, currency } = payload[0].payload

  return (
    <div className='report-graph-tooltip'>
      <div className='report-graph-tooltip__content'>
        <h4 className='report-graph-tooltip__header'>
          {monthFormatter(month)}
        </h4>
      </div>

      <div className='report-graph-tooltip__footer'>
        <div style={{ display: 'flex' }}>
          <div className='recharts-custom-tooltip__label'>
            <FormattedMessage
              id='reports.scrapTrend.scrapActual'
              defaultMessage='Scrap-Actual [%]: '
              description='Label of actual scrap percentage'
            />
          </div>
          <div className='recharts-custom-tooltip__value'>
            {percentageFormatter(percentage)}
          </div>
        </div>
        <br />
        <div className='recharts-custom-tooltip__label'>
          <FormattedMessage
            id='reports.scrapTrend.scrapVariance'
            defaultMessage='Scrap-Variance: '
            description='Label of actual scrap value'
          />
        </div>
        <div className='recharts-custom-tooltip__value'>
          {`${currency}${valueFormatter(value)}`}
        </div>
      </div>
    </div>
  )
}

ScrapTrendTooltip.propTypes = {
  payload: PropTypes.array
}

const ScrapTrendGraph = ({ data, percentageDomain, valueDomain }) => {
  return (
    <div
      style={{ width: '100%', height: 500, marginTop: 18, marginBottom: 18 }}
    >
      <ResponsiveContainer>
        <ComposedChart
          width={600}
          height={400}
          data={data}
          margin={{ top: 20, right: 20, bottom: 120, left: 20 }}
        >
          <CartesianGrid stroke='#f5f5f5' />
          <ReferenceLine y={0} stroke='#000' yAxisId='left' />
          <XAxis dataKey='month' interval={0} tickFormatter={monthFormatter} />
          <YAxis
            yAxisId='right'
            orientation='right'
            dataKey='percentage'
            domain={[-1, 1]}
            tickFormatter={percentageFormatter}
            tickCount={11}
          />
          <YAxis
            yAxisId='left'
            orientation='left'
            dataKey='value'
            tickCount={11}
            domain={valueDomain}
          />
          <Bar yAxisId='right' dataKey='percentage' barSize={40} fill='#2F81B7'>
            <LabelList position='top' formatter={percentageFormatter} />
          </Bar>
          <Bar yAxisId='left' dataKey='value' barSize={40}>
            {data.map(entry => (
              <Cell
                key={`cell-${entry.month}`}
                fill={entry.value >= 0 ? '#30AD64' : '#BF3A31'}
              />
            ))}
            <LabelList position='top' formatter={valueFormatter} />
          </Bar>
          <Line
            yAxisId='right'
            type='monotone'
            dataKey='threshold'
            stroke='#9E2B0E'
            animationDuration={500}
            strokeWidth={3}
            dot={{ r: 5, fill: '#fff', strokeWidth: 3, stroke: '#9E2B0E' }}
            activeDot={{ r: 5, fill: '#9E2B0E', strokeWidth: 0 }}
          />
          <Tooltip content={<ScrapTrendTooltip />} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

ScrapTrendGraph.propTypes = {
  data: PropTypes.array,
  percentageDomain: PropTypes.arrayOf(PropTypes.number),
  valueDomain: PropTypes.arrayOf(PropTypes.number)
}

const mapStateToProps = state => ({
  data: selectReports(state),
  valueDomain: selectValueDomain(state)
})

export default connect(mapStateToProps)(ScrapTrendGraph)
