import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import Actions from 'reports/scrapTrend/actions'
import navigation from 'containers/Navigation/reducer'

const config = handleActions(
  {
    [Actions.SET_TOKEN]: (state, { payload: token }) => ({ ...state, token })
  },
  { token: null, locale: null, timezone: null }
)

const init = handleActions(
  {
    [Actions.START_INIT]: state => ({ ...state, isFinished: false }),
    [Actions.FINISH_INIT]: state => ({ ...state, isFinished: true })
  },
  { isFinished: false }
)

const plant = handleActions(
  {},
  { name: '' }
)

const reports = handleActions(
  {
    [Actions.RECEIVE_REPORTS]: (state, { payload }) => payload
  },
  []
)

export default combineReducers({
  config,
  init,
  plant,
  navigation,
  reports
})
