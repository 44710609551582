import { createSelector } from 'reselect'

export const selectToken = state => state.config.token
export const selectTimezone = state => state.config.timezone
export const selectPlant = state => state.plant
export const selectNavigation = state => state.navigation
export const selectNavigationDate = state => state.navigation.date
export const selectReports = (state) => state.reports

export const selectValueDomain = createSelector(
  selectReports,
  (reports) => {
    const maxAbsValue = Math.max(...reports.map(r => Math.abs(r.value)))
    const borderValue = Math.ceil(maxAbsValue / 1000) * 1000
    return [-borderValue, borderValue]
  }
)
