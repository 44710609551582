import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Loader from 'components/Loader'
import ScrapTrendReportHeader from 'reports/scrapTrend/ScrapTrendReportHeader'
import ScrapTrendReportGraph from 'reports/scrapTrend/ScrapTrendReportGraph'

const ScrapTrendReport = ({ isLoaded }) => {
  if (isLoaded) {
    return (
      <React.Fragment>
        <ScrapTrendReportHeader />
        <ScrapTrendReportGraph />
      </React.Fragment>
    )
  } else {
    return <Loader full />
  }
}

ScrapTrendReport.propTypes = {
  isLoaded: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  isLoaded: state.init.isFinished
})

export default connect(mapStateToProps)(ScrapTrendReport)
