import { fork, call, put, select, takeLatest, race, take, delay } from 'redux-saga/effects'
import moment from 'moment-timezone'
import api from 'lib/api/client'
import {
  startInit,
  finishInit,
  receiveReports
} from 'reports/scrapTrend/actions'
import heartbeat from 'lib/saga/heartbeat'
import {
  selectToken,
  selectTimezone
} from 'reports/scrapTrend/selectors'
import { SET_NAVIGATION, setNavigationDate, setNavigationLocations, configureNavigation } from 'containers/Navigation/actions'
import { selectNavigationRange, selectNavigationLocation } from 'containers/Navigation/selectors'
import { TYPE_YEAR_TO_DATE_WITH_LOCATION } from 'containers/Navigation/const'

const AUTORELOAD_INTERVAL = 60 * 1000

function * init () {
  const token = yield select(selectToken)
  const timezone = yield select(selectTimezone)

  yield put(startInit())

  const locations = yield call(api.get, '/locations', { token })

  yield put(configureNavigation(TYPE_YEAR_TO_DATE_WITH_LOCATION))
  yield put(setNavigationDate(moment.tz({ hour: 0 }, timezone)))
  yield put(setNavigationLocations(locations))

  yield put(finishInit())
}

function * reports () {
  const token = yield select(selectToken)
  const timezone = yield select(selectTimezone)
  const timeParams = yield select(selectNavigationRange, timezone)
  const location = yield select(selectNavigationLocation)
  const params = { ...timeParams, location_id: location.id }

  const reports = yield call(api.get, '/reports/scrap_trend', { params, token })

  yield put(receiveReports(reports))
}

function * reload () {
  let result

  while (true) {
    result = yield race({
      action: take([
        SET_NAVIGATION
      ]),
      timeout: delay(AUTORELOAD_INTERVAL)
    })

    if (result.timeout) {
      yield call(reports)
    }
  }
}

export default function * root () {
  if (process.env.NODE_ENV === 'development') {
    yield fork(heartbeat, 'Reports:ScrapTrend')
  }

  yield call(init)
  yield call(reports)
  yield takeLatest([
    SET_NAVIGATION
  ], reports)

  yield fork(reload)
}
